<template>
    <path class="primary" opacity="0.05" d="m218.5 152.2c0 37.3-30.2 67.5-67.5 67.5s-67.5-30.2-67.5-67.5 30.2-67.5 67.5-67.5c37.2 0 67.5 30.2 67.5 67.5z"/>
    <path d="m80.9 91.9c0 1.3-1.1 2.4-2.4 2.4s-2.5-1-2.5-2.4c0-1.3 1.1-2.4 2.4-2.4 1.4 0 2.5 1.1 2.5 2.4z"/>
    <path d="m90.3 91.9c0.1 1.4-1 2.7-2.4 2.7s-2.7-1-2.7-2.4c-0.1-1.4 1-2.7 2.4-2.7h0.2c1.3-0.1 2.4 1 2.5 2.4z"/>
    <path d="m99.9 91.9c0 1.3-1.1 2.4-2.4 2.4s-2.4-1.1-2.4-2.4 1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4z"/>
    <path d="m351.9 236.6h-53.4l7.3-12.3c0.1-0.2 0.2-0.5 0.2-0.7 0-0.8-0.6-1.4-1.4-1.4h-20.6v-129.7c0-9.4-6.4-16.5-14.9-16.5h-31.9c-0.9 0-1.6 0.7-1.6 1.6s0.7 1.6 1.6 1.6h31.9c7.7 0 11.7 6.7 11.7 13.4v129.7h-88.4c-0.5 0-1 0.3-1.2 0.7l-2.8 4.5-14.2-0.1h-0.3-0.2l-14.2 0.1-2.8-4.5c-0.3-0.4-0.7-0.7-1.2-0.7h-76.7v-15.3c0-0.8-0.6-1.4-1.4-1.4s-1.4 0.6-1.4 1.4v15.1h-7.7v-129.6c0-7 6.7-13.4 14.1-13.4h21.6c0.9 0 1.6-0.7 1.6-1.6s-0.7-1.6-1.6-1.6h-21.8c-9.1 0.1-17.2 7.8-17.2 16.6v129.7h-21.5c-0.3 0-0.5 0.1-0.7 0.2-0.7 0.4-0.9 1.3-0.5 2l7.3 12.3h-44.6c-0.9 0-1.6 0.7-1.6 1.6s0.7 1.6 1.6 1.6h346.9c0.9 0 1.6-0.7 1.6-1.6 0-1-0.7-1.7-1.6-1.7zm-305.9-11.6h108.8l2.8 4.5c0.3 0.4 0.7 0.7 1.2 0.7l15-0.1h0.2 0.2l15 0.1c0.5 0 1-0.3 1.2-0.7l2.8-4.5h87.9c0.3 0.4 0.7 0.6 1.3 0.6 0.5 0 1-0.3 1.3-0.6h18.3l-6.9 11.6h-242.2l-6.9-11.6z"/>
    <path d="m158.3 10.1c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1c-0.1 2.9-2.3 5.1-5.1 5.1zm0-7.2c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2c-0.1-1.2-1-2.2-2.2-2.2z"/>
    <path d="m82 58.5c-3 0-5.4-2.4-5.4-5.4s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4c0 2.9-2.4 5.4-5.4 5.4zm0-8c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z"/>
    <path d="m316.3 213.7c-3 0-5.4-2.4-5.4-5.4s2.4-5.4 5.4-5.4 5.4 2.4 5.4 5.4-2.4 5.4-5.4 5.4zm0-7.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z"/>
    <path d="m304.3 182.3c-0.1 1.4-1.4 2.4-2.8 2.3s-2.4-1.4-2.3-2.8 1.4-2.4 2.8-2.3c1.4 0.2 2.4 1.4 2.3 2.8z"/>
    <path d="m251.6 42.8-9.4-9.4 9.4-9.4 9.4 9.4-9.4 9.4zm-5.4-9.4 5.3 5.3 5.3-5.3-5.3-5.3-5.3 5.3z"/>
    <path d="m15.5 81.3h-5.6v-5.6c0-0.8-0.6-1.4-1.4-1.4s-1.4 0.6-1.4 1.4v5.6h-5.6c-0.9 0-1.5 0.7-1.5 1.5s0.6 1.4 1.4 1.4h5.6v5.6c0 0.8 0.6 1.4 1.4 1.4s1.4-0.6 1.4-1.4v-5.6h5.6c0.8 0 1.4-0.6 1.4-1.4 0.1-0.8-0.6-1.5-1.3-1.5z"/>
    <path d="m30.7 133.7c-0.9 0-1.6-0.7-1.6-1.6v-31.2c0-0.9 0.7-1.6 1.6-1.6s1.6 0.7 1.6 1.6v31.2c0 0.9-0.7 1.6-1.6 1.6z"/>
    <path d="m271.1 177.3c-0.8 0-1.4-0.6-1.4-1.4v-85.2h-28.4c-0.8 0-1.4-0.7-1.4-1.5s0.6-1.4 1.4-1.4h29.8c0.8 0 1.4 0.6 1.4 1.4v86.6c0 0.8-0.6 1.5-1.4 1.5z"/>
    <path d="m271.1 198.9c-0.8 0-1.4-0.6-1.4-1.4v-13.3c0-0.8 0.7-1.4 1.5-1.4s1.4 0.6 1.4 1.4v13.3c-0.1 0.7-0.7 1.4-1.5 1.4z"/>
    <path d="m77.8 200.9c-0.8 0-1.4-0.6-1.4-1.4v-81.5c0-0.8 0.6-1.4 1.4-1.4s1.4 0.6 1.4 1.4v81.5c0 0.8-0.6 1.4-1.4 1.4z"/>
    <path class="primary" d="m9.1 194.7c-0.1 1.4-1.4 2.4-2.8 2.3-1.3 0-2.4-1.3-2.3-2.7s1.4-2.4 2.8-2.3 2.4 1.3 2.3 2.7z"/>
    <path class="primary" d="m185.5 27.2c-0.1 1.4-1.4 2.4-2.8 2.3s-2.4-1.4-2.3-2.8 1.4-2.4 2.8-2.3c1.4 0.2 2.4 1.4 2.3 2.8z"/>
    <path class="primary" d="m34.4 182-9.4-9.4 9.4-9.4 9.4 9.4-9.4 9.4zm-5.4-9.4 5.3 5.3 5.3-5.3-5.3-5.3-5.3 5.3z"/>
    <path class="primary" d="m317 152.6h-4.9v-4.9c0-0.8-0.6-1.4-1.4-1.4s-1.4 0.6-1.4 1.4v4.9h-4.9c-0.8 0-1.4 0.6-1.4 1.4s0.6 1.4 1.4 1.4h4.9v4.9c0 0.8 0.6 1.4 1.4 1.4s1.4-0.6 1.4-1.4v-4.9h4.9c0.8 0 1.4-0.6 1.4-1.4s-0.6-1.4-1.4-1.4z"/>
    <path class="primary" d="m314.5 116.5c-0.9 0-1.6-0.7-1.6-1.6v-17.1c0-0.9 0.7-1.6 1.6-1.6s1.6 0.7 1.6 1.6v17.2c0 0.8-0.7 1.5-1.6 1.5z"/>
    <path class="primary" d="m256 128.8 5.1-3.8-8.4-11.2-4.9 3.7-0.2-0.2c-0.5-0.6-1.4-0.8-2-0.3l-54.2 40.8c-0.2 0.2-0.4 0.4-0.5 0.7l-4.8 16.2c0 0.1-0.1 0.3-0.1 0.4 0 0.8 0.6 1.4 1.4 1.4h0.2l15.7-1.8c0.3 0 0.5-0.1 0.7-0.3l48.8-37.9 0.3 5.3-15.7 12.8c-0.3 0.3-0.5 0.7-0.5 1.1 0 0.8 0.6 1.4 1.4 1.4 0.3 0 0.7-0.1 0.9-0.3l16.9-13.7-0.5-8.4c0-0.1 0-0.1-0.1-0.2l1.8-1.4c0.6-0.5 0.7-1.4 0.3-2l-1.6-2.3zm-3.9-10.9 4.9 6.6-2.7 2.1-4.9-6.6 2.7-2.1zm-49.5 54.1-13.1 1.5 3.5-11.7 5.4 6.9c0.3 0.4 0.7 0.6 1.1 0.6 0.3 0 0.6-0.1 0.9-0.3 0.6-0.5 0.7-1.4 0.3-2l-6-7.8 51.6-38.8 8.4 11.4-52.1 40.2z"/>
    <path class="primary" d="m153.5 181.2c-2.3 0-2.6-2.6-2.8-4.3-0.1-0.6-0.2-1.2-0.3-2-1.3 0-4 2.3-5.8 4.5-0.5 0.6-1.4 0.7-2 0.2s-0.7-1.4-0.2-2c0.9-1.1 5.3-6.3 8.8-5.5 2 0.4 2.2 2.7 2.4 4.3v0.3c1.2-2.1 2.9-5.5 4.3-8.4 3.6-7.4 4.8-9.5 6-10.1 0.4-0.2 0.8-0.2 1.1 0 2.9 1.1 3 6 2.9 13.4 0 1.7-0.1 3.3 0 4.4 1.3-0.2 4.5-2 7.2-4.1 0.6-0.5 1.5-0.3 2 0.3s0.3 1.5-0.3 2c-3.1 2.4-8.7 6.2-11.2 4-0.2-0.2-0.4-0.5-0.5-0.8-0.2-1.9-0.3-3.9-0.2-5.8 0-2.3 0.1-7.6-0.5-9.8-1 1.7-2.7 5.1-4 7.8-2.8 5.7-4.7 9.6-6 11-0.1 0.4-0.5 0.6-0.9 0.6z"/>
    <path class="primary" d="m137.5 73.6h-15.6c-0.9 0-1.6-0.7-1.6-1.6s0.7-1.6 1.6-1.6h12.5v-14c0-0.9 0.7-1.6 1.6-1.6s1.6 0.7 1.6 1.6v17.2z"/>
    <rect class="primary" x="160.1" y="66.9" width="29" height="2.9"/>
    <rect class="primary" x="144.2" y="94.2" width="58.3" height="2.9"/>
    <rect class="primary" x="144.2" y="84.3" width="63.2" height="2.9"/>
    <rect class="primary" x="162.7" y="104.1" width="31" height="2.9"/>
    <rect class="primary" x="144.2" y="104.1" width="12.3" height="2.9"/>
    <rect class="primary" x="144.2" y="127.6" width="58.3" height="2.9"/>
    <rect class="primary" x="162.7" y="137.5" width="31" height="2.9"/>
    <rect class="primary" x="144.2" y="137.5" width="12.3" height="2.9"/>
    <path class="primary" d="m115.6 198.9h-2.9v-128.2l20.8-24.6 95.7 0.2 0.5 71.9h-2.9l-0.5-69.1-91.5-0.1-19.2 22.8v127.1z"/>
    <path class="primary" d="m229.8 199.2h-104.6v-2.9h101.7v-20.5h2.9v23.4z"/>
    <path class="primary" opacity="0.43" d="m247.6 117.3 8.9 15.1-52.5 42-11.5-15.7 55.1-41.4z"/>
    <path d="m42.2 61.9v31.5c0 0.9 0.7 1.6 1.6 1.6s1.6-0.7 1.6-1.6v-30.8l0.6-0.5 8-6.8 1.2-0.9 0.1-0.1s0.1 0 0.1-0.1c0.1-0.1 0.1-0.2 0.2-0.3v-0.1c0.1-0.1 0.1-0.3 0.1-0.6v-21.3l3.4 3.4c0.8 0.7 1.7 0.7 2.4 0 0.7-0.8 0.7-1.7 0-2.4l-6.1-6.1c0-0.1-0.1-0.1-0.1-0.1-0.2-0.1-0.3-0.2-0.5-0.2-0.2-0.1-0.5-0.1-0.7-0.1s-0.6 0-0.7 0.1c0 0.1-0.1 0.1-0.2 0.2l-6.2 6.2c-0.7 0.8-0.7 1.7-0.1 2.4 0.8 0.7 1.7 0.7 2.4 0l3.4-3.4v20.5l-0.7 0.4-8 6.8-1.2 0.9c-0.4 0.4-0.6 0.9-0.6 1.4z"/>
    <path class="primary" d="m115.6 18.5-6-6c-0.1-0.1-0.2-0.1-0.2-0.2-0.1-0.1-0.4-0.1-0.7-0.1s-0.4 0-0.7 0.1c-0.1 0-0.2 0.1-0.4 0.2 0 0-0.1 0-0.1 0.1l-5.9 5.9c-0.7 0.7-0.7 1.6 0 2.3s1.6 0.7 2.3 0l3.2-3.2v21c0 0.9 0.7 1.6 1.6 1.6s1.6-0.7 1.6-1.6v-21l3.2 3.2c0.7 0.7 1.6 0.7 2.3 0 0.4-0.6 0.4-1.6-0.2-2.3z"/>
    <path class="primary" d="m310.4 63.9-6-6c-0.1-0.1-0.2-0.1-0.2-0.2-0.1-0.1-0.4-0.1-0.7-0.1s-0.4 0-0.7 0.1c-0.1 0-0.2 0.1-0.4 0.2 0 0-0.1 0-0.1 0.1l-5.9 5.9c-0.7 0.7-0.7 1.6 0 2.3s1.6 0.7 2.3 0l3.3-3.2v39c0 0.9 0.7 1.6 1.6 1.6s1.6-0.7 1.6-1.6v-39l3.2 3.2c0.7 0.7 1.6 0.7 2.3 0 0.4-0.6 0.4-1.6-0.3-2.3z"/>
    <rect class="primary" x="132.6" y="84.2" width="6.4" height="2.9"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 353.5, height: 239.7 });
    },
    emits: ['dimensions'],
};
</script>
